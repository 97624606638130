export const Login = async (username: string, password: string, app_id?: string, redirect_url?: string) => {
    const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ app_id, email: username, password, redirect_url }),
        headers: { 'Content-Type': 'application/json' }
    })
    const result = await data.json()
    console.log(result, 'wew')
    if (data.status === 200) {
        return result
    } else {
        throw new Error(result.detail)
    }
}