import {
  ChakraProvider,
  theme
} from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { Bootstrap } from "./Bootstrap"
import ForgotPasswordPage from "./modules/auth/pages/ForgotPasswordPage"
import LoginPage from "./modules/auth/pages/LoginPage"
import ResetPasswordPage from "./modules/auth/pages/ResetPasswordPage"
import { UserProvider } from "./providers/UserProvider"

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <UserProvider>
        <Routes>
          <Route path='/' Component={Bootstrap} />
          <Route path='/login' Component={LoginPage} />
          <Route path='/forgot-password' Component={ForgotPasswordPage} />
          <Route path='/reset-password' Component={ResetPasswordPage} />
        </Routes>
      </UserProvider>
    </ChakraProvider>
  )
}