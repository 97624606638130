import { Box, Button, Center, Checkbox, Flex, FormControl, FormLabel, Img, Input, InputGroup, InputRightElement, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react"
import { useContext, useState } from "react"
import { FiEye, FiEyeOff } from "react-icons/fi"
import { useNavigate, useSearchParams } from "react-router-dom"
import { UserContext } from "../../../providers/UserProvider"
import { Login } from "../../../services/AuthService"

const LoginPage = () => {
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState(false)
    const handleShowPassword = () => setShowPassword(!showPassword)
    const [is_loading, setIsLoading] = useState<boolean>(false)
    const { setUser } = useContext(UserContext)
    const navigate = useNavigate()
    const toast = useToast()
    const [searchParams] = useSearchParams();
    const buttonColor = useColorModeValue("rgb(0, 174, 239)", "rgb(0, 174, 239)")

    const login = async () => {
        setIsLoading(() => true)
        try {
            const app_id = searchParams.get('app_id') as string
            const redirect_url = searchParams.get('redirect_url') as string
            const result = await Login(username, password, app_id, redirect_url)
            const { code } = result
            window.location.href = `${redirect_url}?code=${code}`
        } catch (e: any) {
            console.log(e, 'error')
            toast({
                status: 'error',
                description: e.message,
                duration: 3000,
                position: 'bottom-right'
            })
        }
        await new Promise(r => setTimeout(r, 1000))
        setIsLoading(() => false)
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={'rgb(0, 174, 239)'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Center w={'450px'}>
                    <Img src={'/images/as-logo-white.png'} w={'160px'} />
                </Center>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <FormControl>
                            <FormLabel fontWeight={'500'}>Email</FormLabel>
                            <Input type="text" autoComplete={'off'} value={username} onChange={e => setUsername(e.target.value)} />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight={'500'}>Password</FormLabel>
                            <InputGroup size='md'>
                                <Input pr='4.5rem'
                                    type={showPassword ? 'text' : 'password'}
                                    value={password} onChange={e => setPassword(e.target.value)} />
                                <InputRightElement onClick={handleShowPassword}>
                                    {showPassword ? <FiEyeOff /> : <FiEye />}
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>
                        <Stack spacing={10} pt={'16px'}>
                            {/* <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                align={'start'}
                                justify={'space-between'}>
                                <Checkbox fontWeight={'400'}>Remember me</Checkbox>
                                <Text fontWeight={'400'} cursor={'pointer'} color={'gray.400'}>Forgot password?</Text>
                            </Stack> */}
                            <Button
                                isLoading={is_loading}
                                loadingText={'Logging in'}
                                onClick={login}
                                bg={buttonColor}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.400',
                                }}>
                                Login
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    )
}

export default LoginPage