import { Box, Button, Center, Flex, FormControl, FormLabel, Img, Input, InputGroup, InputRightElement, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import { ResetPassword, ValidateResetPassword } from "../../../services/Users"
import { useNavigate, useLocation } from "react-router-dom"
import { FiEye, FiEyeOff, FiAlertCircle } from "react-icons/fi";

const ResetPasswordPage = () => {
    const navigate = useNavigate()
    const toast = useToast()
    const location = useLocation();
    const buttonColor = useColorModeValue("rgb(0, 174, 239)", "rgb(0, 174, 239)")
    const dangerColor = useColorModeValue("#E53E3E", "#E53E3E")

    const [newPassword, setNewPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [showNewPassword, setShowNewPassword] = useState(false)
    const handleShowNewPassword = () => setShowNewPassword(!showNewPassword)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const handleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword)

    const [is_loading, setIsLoading] = useState<boolean>(false)
    const [is_reset_sucess, setResetSuccess] = useState<boolean>(false)
    const [is_user_authorized, setUserAuthorized] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            const searchParams = new URLSearchParams(location.search);
            const token = searchParams.get('token');
            if (token) {
                try {
                    const result = await ValidateResetPassword(token)
                    setUserAuthorized(() => result)
                } catch (e: any) {
                    setUserAuthorized(() => false)
                }
            }
        })()
    })

    const reset = async () => {
        setIsLoading(() => true)
        try {
            const result = await ResetPassword(newPassword, confirmPassword)
            if (result) {
                setResetSuccess(() => true)
                if (is_reset_sucess) {
                    toast({
                        status: 'success',
                        description: result.response.data.detail,
                        duration: 3000,
                        position: 'bottom-right'
                    })
                }
            }
        } catch (e: any) {
            toast({
                status: 'error',
                description: e.message,
                duration: 3000,
                position: 'bottom-right'
            })
        }
        await new Promise(r => setTimeout(r, 1000))
        setIsLoading(() => false)
    }

    const login = async () => {
        navigate('/login');
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={'rgb(0, 174, 239)'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Center w={'450px'}>
                    <Img src={'/images/as-logo-white.png'} w={'160px'} />
                </Center>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    {
                        ( is_user_authorized && 
                            <>
                                <Stack textAlign={'center'}>
                                    <Text fontSize='3xl' fontWeight={'300'}>Reset Password</Text>
                                </Stack>
                                <Stack mb={2} textAlign={'center'}>
                                    <Text fontSize='md' fontWeight={'200'}>Please enter your new password.</Text>
                                </Stack>
                                <Stack spacing={4}>
                                    <FormControl>
                                        <FormLabel fontWeight={'500'}>New Password</FormLabel>
                                        <InputGroup size='md'>
                                            <Input pr='4.5rem'
                                                type={showNewPassword ? 'text' : 'password'}
                                                value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                            <InputRightElement onClick={handleShowNewPassword}>
                                                {showNewPassword ? <FiEyeOff /> : <FiEye />}
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel fontWeight={'500'}>Confirm Password</FormLabel>
                                        <InputGroup size='md'>
                                            <Input pr='4.5rem'
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                            <InputRightElement onClick={handleShowConfirmPassword}>
                                                {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                                            </InputRightElement>
                                        </InputGroup>
                                    </FormControl>
                                    <Stack>
                                        <Button
                                            isLoading={is_loading}
                                            loadingText={'Logging in'}
                                            onClick={reset}
                                            bg={buttonColor}
                                            color={'white'}
                                            _hover={{
                                                bg: 'blue.300',
                                            }}>
                                            Submit
                                        </Button>
                                    </Stack>
                                </Stack>
                            </>
                        ) ||
                        ( !is_user_authorized && 
                            <Stack spacing={4} textAlign={'center'}>
                                <Stack align={'center'}>
                                    <FiAlertCircle  fontSize='70' color={dangerColor} />
                                    <Text fontSize='3xl' fontWeight={'300'} alignItems={'center'}>Unauthorized User</Text>
                                </Stack>
                                <Stack textAlign={'center'}>
                                    <Text fontSize='md' fontWeight={'200'}>Please login to continue with this operation.</Text>
                                </Stack>
                                <Button
                                    bg={'white'}
                                    onClick={login}
                                    color={buttonColor}
                                    _hover={{
                                        bg: 'white',
                                    }}>
                                    Back to Login
                                </Button>
                            </Stack>
                        )
                    }
 
                </Box>
            </Stack>
        </Flex>
    )
}

export default ResetPasswordPage