export const GetCurrent = async () => {
    const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/current`, {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
    })
    const result = await data.json()
    if (data.status === 200) {
        return result
    } else {
        throw new Error(result.detail)
    }
}