import { Avatar, Box, Center, HStack, Img, Text, VStack } from "@chakra-ui/react"
import { useUserContext } from "../../../providers/UserProvider"

export const ProfilePage = () => {
    const { user } = useUserContext()

    const view = (app: any) => {
        window.open(app.url, '_blank')
    }

    return (
        <VStack bg={'gray.50'} align={'center'} justify={'center'} h={'100vh'} w={'100%'}>
            <VStack spacing={'80px'}>
                <VStack spacing={'16px'}>
                    <Avatar shadow={'xl'} size={'xl'} src={user.picture_url} name={user.name} />
                    <VStack spacing={0}>
                        <Text color={'gray.600'} fontSize={'20px'} fontWeight={700}>{user.name}</Text>
                        <Text color={'gray.600'} fontSize={'12px'} fontWeight={500}>{user.email}</Text>
                    </VStack>
                </VStack>
                <VStack align={'start'} spacing={'16px'}>
                    <Text color={'gray.600'} fontSize={'20px'} fontWeight={700}>Your Apps</Text>
                    <HStack spacing={'24px'}>
                        {user.apps.map((item: any, index: number) =>
                            <VStack onClick={() => view(item)} cursor={'pointer'} spacing={'8px'} shadow={'lg'} borderRadius={'8px'} bg={'white'} p={'16px'} key={index}>
                                <Center overflow={'hidden'} w={'80px'} h={'60px'}>
                                    <Img maxW={'full'} maxH={'full'} src={item.logo} />
                                </Center>
                                <Text color={'gray.600'} fontWeight={500} fontSize={'12px'}>{item.name}</Text>
                            </VStack>
                        )}
                    </HStack>
                </VStack>
            </VStack>
        </VStack>
    )
}