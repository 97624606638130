import { Context, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { GetCurrent } from "../services/UserService";

export interface UserContextProps {
  isReady: boolean;
  setIsReady: (isReady: boolean) => void;
  user: any;
  setUser: (user: any) => void;
}

interface UserProviderProps {
  children: ReactNode
}

export const UserContext = createContext<UserContextProps>({
  isReady: false,
  setIsReady: (isReady: any) => { },
  user: null,
  setUser: (user: any) => { }
})

export const useUserContext = () => {
  const currentUserContext = useContext(UserContext);

  if (!currentUserContext) {
    throw new Error(
      "useUserContext has to be used within <UserContext.Provider>"
    );
  }

  return currentUserContext;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<any>()
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      try {
        const user = await GetCurrent()
        setUser(() => user)
      } catch (e: any) {
        console.log(`GetCurrent`, e)
      }
      setIsReady(() => true)
    })()
  }, [])

  return (
    <UserContext.Provider value={{
      user, setUser,
      isReady, setIsReady
    }}>
      {children}
    </UserContext.Provider>
  )
}