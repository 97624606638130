import { Text } from "@chakra-ui/react"
import LoginPage from "./modules/auth/pages/LoginPage"
import { useUserContext } from "./providers/UserProvider"
import { ProfilePage } from "./modules/account/pages/ProfilePage"

export const Bootstrap = () => {
    const { user, isReady } = useUserContext()

    return (
        isReady &&
        (
            user &&
            <ProfilePage />
            ||
            <LoginPage />
        )
        ||
        (
            <Text fontSize={'x-large'} p={'16px'}>
                Loading, please wait...
            </Text>
        )

    )
}