import qs from "query-string";
import axios from "axios"

export const GetCurrent = async () => {
    const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/me`, {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' }
    })
    const result = await data.json()
    if (data.status === 200) {
        return result
    } else {
        throw new Error(result.detail)
    }
}

export const Login = async (app_id: string, username: string, password: string) => {
    const data = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ app_id, username, password }),
        headers: { 'Content-Type': 'application/json' }
    })
    const result = await data.json()
    if (data.status === 200) {
        return result
    } else {
        throw new Error(result.detail)
    }
}

export const ForgotPassword = async (username: string)=> {
    const { data } = await axios.get(`${process.env.REACT_APP_API_LOCAL_URL}/api/accounts/v1/forgot-password?username=${username}`, {
        withCredentials: true
    })
    return data
}

export const ValidateResetPassword = async (token: string)=> {
    const { data } = await axios.get(`${process.env.REACT_APP_API_LOCAL_URL}/api/accounts/v1/validate-reset-password?reset_token=${token}`, {
        withCredentials: true
    })
    return data
}

export const ResetPassword = async (newPassword: string, confirmPassword: string) => {
    const data = await fetch(`${process.env.REACT_APP_API_LOCAL_URL}/api/accounts/reset-password`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({newPassword, confirmPassword}),
        headers: { 'Content-Type': 'application/json' }
    })
    const result = await data.json()
    if (data.status === 200) {
        return result
    } else {
        throw new Error(result.detail)
    }
}