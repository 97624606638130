import { Box, Button, Center, Flex, FormControl, Img, Input, InputGroup, InputLeftElement, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react"
import { useState } from "react"
import { ForgotPassword } from "../../../services/Users"
import { useNavigate } from "react-router-dom"
import { FaEnvelope } from "react-icons/fa"
import { FiMail, FiSend } from "react-icons/fi"

const ForgotPasswordPage = () => {
    const [username, setUsername] = useState<string>('')
    const [requestSuccessMsg, setRequestSuccessMsg] = useState<string>('')
    const [is_submitted, setIsSubmitted] = useState<boolean>(false)
    const [is_loading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const toast = useToast()
    const buttonColor = useColorModeValue("rgb(0, 174, 239)", "rgb(0, 174, 239)")

    const submit = async () => {
        setIsLoading(() => true)
        try {
            const result = await ForgotPassword(username)
            if (result) {
                setIsSubmitted(() => true)
                setRequestSuccessMsg(() => result.status)
            }
        } catch (e: any) {
            toast({
                status: 'error',
                description: e.response.data.detail,
                duration: 3000,
                position: 'bottom-right'
            })
        }
        await new Promise(r => setTimeout(r, 1000))
        setIsLoading(() => false)
    }

    const login = async () => {
        navigate('/login')
    }

    return (
        <Flex
            minH={'100vh'}
            align={'center'}
            justify={'center'}
            bg={'rgb(0, 174, 239)'}>
            <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
                <Center w={'450px'}>
                    <Img src={'/images/as-logo-white.png'} w={'160px'} />
                </Center>
                <Box
                    rounded={'lg'}
                    bg={useColorModeValue('white', 'gray.700')}
                    boxShadow={'lg'}
                    p={8}>
                    {   ( is_submitted && 
                            <Stack spacing={5} textAlign={'center'}>
                                <Stack align={'center'}>
                                    <FiMail fontSize='70' color={buttonColor} />
                                    <Text fontSize='3xl' fontWeight={'300'} alignItems={'center'}>{requestSuccessMsg}</Text>
                                </Stack>
                                <Stack spacing={0} textAlign={'center'}>
                                    <Text fontSize='md' fontWeight={'200'}>An email containing instructions to reset password</Text>
                                    <Text fontSize='md' fontWeight={'200'}>has been sent to {username}</Text>
                                </Stack>
                                <Button
                                    bg={'white'}
                                    onClick={login}
                                    color={buttonColor}
                                    _hover={{
                                        bg: 'white',
                                    }}>
                                    Back to Login
                                </Button>
                            </Stack>
                        ) ||
                        ( !is_submitted &&
                            <>
                                <Stack textAlign={'center'}>
                                    <Text fontSize='3xl' fontWeight={'300'}>Forgot Password?</Text>
                                </Stack>
                                <Stack spacing={0} mt={2} mb={5} textAlign={'center'}>
                                    <Text fontSize='md' fontWeight={'200'}>We can send you details on how to reset it.</Text>
                                    <Text fontSize='md' fontWeight={'200'}>Please enter your email address.</Text>
                                </Stack>
                                <Stack spacing={5}>
                                    <FormControl>
                                        <InputGroup>
                                            <InputLeftElement pointerEvents='none'>
                                            <FaEnvelope />
                                            </InputLeftElement>
                                            <Input fontWeight={'200'} type='email' autoComplete={'off'} value={username} onChange={e => setUsername(e.target.value)}/>
                                        </InputGroup>
                                    </FormControl>
                                    <Stack>
                                        <Button
                                            isLoading={is_loading}
                                            loadingText={'Logging in'}
                                            onClick={submit}
                                            bg={buttonColor}
                                            color={'white'}
                                            _hover={{
                                                bg: 'blue.400',
                                            }}>
                                            Submit
                                        </Button>
                                        <Button
                                            bg={'white'}
                                            onClick={login}
                                            color={'blue.400'}
                                            _hover={{
                                                bg: 'white',
                                            }}>
                                            Back to Login
                                        </Button>
                                    </Stack>
                                </Stack>
                            </>
                        )
                    }
                </Box>
            </Stack>
        </Flex>
    )
}

export default ForgotPasswordPage